import React, { useState, useEffect } from "react";
import qs from "qs";
import Axios from "axios";
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { SearchIcon } from "@heroicons/react/outline";

// swiper bundle styles
import "swiper/swiper-bundle.min.css";

// swiper core styles
import "swiper/swiper.min.css";

import { ROOT_URL, API_URL } from "config/config";
import Navbar from "components/Navbar";
import Footer from "components/Footer.js";

SwiperCore.use([Autoplay, Navigation, Pagination, A11y]);

// eslint-disable-next-line import/no-anonymous-default-export
export default (params) => {
  const [dataPeraturan, setDataPeraturan] = useState([]);
  const [counter, setCounter] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [countert, settCounter] = useState(null);
  const [keywords, setKeywords] = useState("");
  const [swiperContent, setSwiperContent] = useState([]);
  const [info, setInfo] = useState({
    ikm: "...",
    ipp: "...",
    irb: "...",
    kod: "...",
    sakip: "...",
  });

  useEffect(() => {
    (async () => {
      const resPeraturan = await Axios.get(
        `${API_URL}/api/list/cv_peraturan_terkait`
      ).then((res) => res.data.cv_peraturan_terkait);
      setDataPeraturan(resPeraturan);
      // console.log(dataPeraturan);
    })();
    handleCounter();
    getSwiper();
    getInfo();
  }, []);

  const handleCounter = () => {
    Axios.get(`${API_URL}/api/view/cv_counter/1`)
      .then((res) => {
        const data = res.data;
        setCounter(data.cv_counter.counter);
        Axios.post(
          `${API_URL}/api/edit/cv_counter/1`,
          qs.stringify({
            counter: parseInt(data.cv_counter.counter) + 1,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const getSwiper = () => {
    Axios.get(`${API_URL}/api/list/cv_swiper`)
      .then((res) => {
        const data = res.data;
        setSwiperContent(data.cv_swiper);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const getInfo = () => {
    Axios.get(`${API_URL}/api/list/cv_info`)
      .then((res) => {
        const data = res.data;
        setInfo(data.cv_info[0]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  return (
    <>
      {/* menampilkan Navigasi */}
      <Navbar transparent />

      <br></br>
      <main
        className="flex mt-10 px-10 w-full bg-blueGray-200 p-4 items-center flex-col md:flex-row"
        id="faq"
      >
        <div className="w-full md:w-5/12 mr-auto ml-auto mb-4 order-2 md:order-1">
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            navigation
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            loop
            className="mb-10 h-98"
          >
            {swiperContent.map((item) => (
              <SwiperSlide key={item.id}>
                <div className="w-full h-full bg-center bg-cover rounded-lg">
                  <img
                    src={`https://satudata.banjarnegarakab.go.id/data_csv/${item.gambar.replace(
                      " ",
                      "%20"
                    )}`}
                    alt={`Slide ${item.id}`}
                    className="w-full h-full rounded-lg object-cover"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="w-full md:w-6/12 px-4 ">
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            navigation
            // autoplay={{
            //   delay: 5000,
            //   disableOnInteraction: false,
            // }}
            pagination={{ clickable: true }}
            loop
            className=""
          >
            <SwiperSlide>
              <div className="flex flex-wrap">
                <div className="w-full md:w-4/12 px-4 mt-2">
                  <div className="relative flex flex-col">
                    <div className="px-4 py-5 flex-auto">
                      <a href="/Dashboard/ListObjek">
                        <div className="bg-red-700 shadow-lg rounded-lg text-center p-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/social-network-connections_1010-422.jpg?w=740&t=st=1700191467~exp=1700192067~hmac=0d66e4cd9d438377c66ca1c680912e77701aee12c303487224ffa83739ef72cd"
                          />
                          <p className="text-sm text-white text-center mt-1 font-semibold ">
                            Data OPD
                          </p>
                        </div>
                      </a>
                    </div>

                    <div className="px-4 py-5 flex-auto">
                      <a href="#cari">
                        <div className="bg-red-700 shadow-lg rounded-lg text-center p-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/new-team-members-concept-illustration_114360-7721.jpg?w=740&t=st=1700192805~exp=1700193405~hmac=9c778e6b58462e3b9ec591e346a32c73e9f4b147f62d0f30ba64378c36b67612"
                          />
                          <p className="text-sm text-white mt-1 font-semibold">
                            Cari Data
                          </p>
                        </div>
                      </a>
                    </div>

                    <div className="px-4 py-5 flex-auto">
                      <a href="#tentang">
                        <div className="bg-red-700 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/add-user-concept-illustration_114360-458.jpg?w=740&t=st=1700194085~exp=1700194685~hmac=3d44e4983c90907c51e8749ddd9c3ae23218299d814f3e71f7d20fe2a1c4ce41"
                          />
                          <p className="text-sm text-white mt-1 font-semibold">
                            Kontak Kami
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-4/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-1">
                    <div className="px-4 py-5 flex-auto">
                      <a href="/Dashboard/ListAspekIku">
                        <div className="bg-blueGray-700 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/blogging-isometric-concept-with-content-plan-making-process-3d-illustration_1284-55140.jpg?w=740&t=st=1700195795~exp=1700196395~hmac=8ad50f6b013a392818991ac047e5a598f16ebb6ebdbaee4eee46d718f2803404"
                          />
                          <p className="text-sm text-white mt-1 font-semibold">
                            IKU
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <a href="#f&q">
                        <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/timeline-concept-illustration_114360-2297.jpg?w=740&t=st=1700195994~exp=1700196594~hmac=2c603cd28756790fbd6ba242ad60342240576927335d9b2f6cc803be41c7854e"
                          />
                          <p className="text-sm text-white mt-1 font-semibold">
                            F&Q
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <a href="https://www.creative-tim.com/learning-lab/tailwind/vue/alerts/notus?ref=vtw-index">
                        <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/site-stats-concept-illustration_114360-1434.jpg?w=740&t=st=1700196477~exp=1700197077~hmac=6f7843010e92a986a039ec4a5a3536cd3be7fc9d52cdc4b6e6df74f7dcdf3788"
                          />
                          <p className="text-sm text-white mt-2 font-semibold">
                            Walidata
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0"></div>
                </div>

                <div className="w-full md:w-4/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-1">
                    <div className="px-4 py-5 flex-auto">
                      <a href="/Dashboard/ListAspekIkk">
                        <div className="bg-blueGray-700 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/man-holding-clock-time-management-concept_23-2148823171.jpg?w=740&t=st=1700196162~exp=1700196762~hmac=4369dd9fdf0e39782f49251ac6ce3624e26240c6f8045e73c46971a3f667d090"
                          />
                          <p className="text-sm text-white mt-1 font-semibold">
                            IKK
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <a href="#peraturan">
                        <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/legal-statement-court-notice-judge-decision-judicial-system-lawyer-attorney-studying-papers-cartoon-character_335657-1598.jpg?w=740&t=st=1700196328~exp=1700196928~hmac=e90371ca6793d4fc7bd56aab0acdd63a29e9008a8bdaeb63dd1e1b4fa14685c0"
                          />
                          <p className="text-sm text-white mt-1 font-semibold">
                            Peraturan
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <a href="/Dashboard/ListSAKIP">
                        <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/focus-group-business-research-data-analytics-company-profitable-strategy-planning-dartboard-computer-monitor-corporate-goals-achievements-concept-illustration_335657-1764.jpg?t=st=1738125403~exp=1738129003~hmac=5d027d7b2bc0ed4082db8a991399b7ad5b3c8ccc6c1de4966b306044468cc927&w=740"
                          />
                          <p className="text-sm text-white mt-1 font-semibold">
                            SAKIP
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-wrap">
                <div className="w-full md:w-4/12 px-4 mt-2">
                  <div className="relative flex flex-col">
                    <div className="px-4 py-5 flex-auto">
                      <a href="/Dashboard/Ebook">
                        {/* <a href="https://heyzine.com/flip-book/c1e986923e.html#page/1"> */}
                        <div className="bg-red-700 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/library-concept-with-online-reading-ebooks-symbols-isometric_1284-33405.jpg?w=740&t=st=1700451472~exp=1700452072~hmac=ad6cec07f060bbaba3cfeeea014a058d212b6ce930ca6f331d0f568ac64e477c"
                          />
                          <p className="text-sm text-white mt-1 font-semibold">
                            E-books
                          </p>
                        </div>
                      </a>
                    </div>

                    {
                      <div className="px-4 py-5 flex-auto">
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSc_PFT3f07KVxSGZ-0hSpMqX9VBmO6jW5cpsGvVmAnIIOxnzQ/viewform?usp=sf_link">
                          <div className="bg-red-700 shadow-lg rounded-lg text-center p-1 max-w-md mx-auto">
                            <img
                              alt="..."
                              className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                              src="https://img.freepik.com/free-vector/new-team-members-concept-illustration_114360-7721.jpg?w=740&t=st=1700192805~exp=1700193405~hmac=9c778e6b58462e3b9ec591e346a32c73e9f4b147f62d0f30ba64378c36b67612"
                            />
                            <p className="text-sm text-white mt-1 font-semibold">
                              Kuisioner
                            </p>
                          </div>
                        </a>
                      </div>

                      /*<div className="px-4 py-5 flex-auto">
                      <a href="#tentang">
                        <div className="bg-red-700 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/add-user-concept-illustration_114360-458.jpg?w=740&t=st=1700194085~exp=1700194685~hmac=3d44e4983c90907c51e8749ddd9c3ae23218299d814f3e71f7d20fe2a1c4ce41"
                          />
                          <p className="text-sm text-white mt-1 font-semibold">
                            Kontak Kami
                          </p>
                        </div>
                      </a>
                    </div> */
                    }
                  </div>
                </div>

                {/* <div className="w-full md:w-4/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-1">
                    <div className="px-4 py-5 flex-auto">
                      <a href="/Dashboard/ListAspekIku">
                        <div className="bg-blueGray-700 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/blogging-isometric-concept-with-content-plan-making-process-3d-illustration_1284-55140.jpg?w=740&t=st=1700195795~exp=1700196395~hmac=8ad50f6b013a392818991ac047e5a598f16ebb6ebdbaee4eee46d718f2803404"
                          />
                          <p className="text-sm text-white mt-1 font-semibold">
                            IKU
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <a href="#f&q">
                        <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/timeline-concept-illustration_114360-2297.jpg?w=740&t=st=1700195994~exp=1700196594~hmac=2c603cd28756790fbd6ba242ad60342240576927335d9b2f6cc803be41c7854e"
                          />
                          <p className="text-sm text-white mt-1 font-semibold">
                            F&Q
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <a href="https://www.creative-tim.com/learning-lab/tailwind/vue/alerts/notus?ref=vtw-index">
                        <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/site-stats-concept-illustration_114360-1434.jpg?w=740&t=st=1700196477~exp=1700197077~hmac=6f7843010e92a986a039ec4a5a3536cd3be7fc9d52cdc4b6e6df74f7dcdf3788"
                          />
                          <p className="text-sm text-white mt-2 font-semibold">
                            Walidata
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0"></div>
                </div> */}

                {/* <div className="w-full md:w-4/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-1">
                    <div className="px-4 py-5 flex-auto">
                      <a href="/Dashboard/ListAspekIkk">
                        <div className="bg-blueGray-700 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/man-holding-clock-time-management-concept_23-2148823171.jpg?w=740&t=st=1700196162~exp=1700196762~hmac=4369dd9fdf0e39782f49251ac6ce3624e26240c6f8045e73c46971a3f667d090"
                          />
                          <p className="text-sm text-white mt-1 font-semibold">
                            IKK
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <a href="#peraturan">
                        <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/illustrated-woman-being-intern-company_23-2148726151.jpg?w=740&t=st=1700196261~exp=1700196861~hmac=1bd53fc72f0baead93c0f1f082bb6d860037a4adf84d6e195f6acfa706595687"
                          />
                          <p className="text-sm text-white mt-1 font-semibold">
                            Peraturan
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <a href="/Dashboard/ListTujuan">
                        <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                          <img
                            alt="..."
                            className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                            src="https://img.freepik.com/free-vector/legal-statement-court-notice-judge-decision-judicial-system-lawyer-attorney-studying-papers-cartoon-character_335657-1598.jpg?w=740&t=st=1700196328~exp=1700196928~hmac=e90371ca6793d4fc7bd56aab0acdd63a29e9008a8bdaeb63dd1e1b4fa14685c0"
                          />
                          <p className="text-sm text-white mt-1 font-semibold">
                            Sakip
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        {/* <div className="px-4 py-5 flex-auto">
              <a href="https://heyzine.com/flip-book/2b0c85da36.html">
                <div className="bg-red-700 shadow-lg rounded-lg text-center p-1 mt-1 max-w-md mx-auto">
                  <img
                    alt="..."
                    className="object-cover w-full h-32 mx-auto mb-1 rounded-lg"
                    src="https://img.freepik.com/free-vector/library-concept-with-online-reading-ebooks-symbols-isometric_1284-33405.jpg?w=740&t=st=1700451472~exp=1700452072~hmac=ad6cec07f060bbaba3cfeeea014a058d212b6ce930ca6f331d0f568ac64e477c"
                  />
                  <p className="text-sm text-white mt-1 font-semibold">Ebook</p>
                </div>
              </a>
            </div> */}
      </main>

      {/* <div className="h-screen">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          navigation
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
          loop
          className="mb-10 h-screen"
          style={{
            height: "83%",
          }}
        >
          {swiperContent.map((item) => (
            <SwiperSlide>
              <div
                className="w-full h-full bg-center bg-cover"
                style={{
                  backgroundImage: `url(https://satudata.banjarnegarakab.go.id/data_csv/${item.gambar.replace(
                    " ",
                    "%20"
                  )})`,
                }}
              >
                <div
                  className="h-full flex flex-col items-center justify-center"
                  style={{ backgroundColor: "rgba(0,0,0,.1)" }}
                >
                  <div
                    className="p-4 w-10/12 mx-auto"
                    style={{ backgroundColor: "rgba(0,0,0,.5)" }}
                  >
                    <h1 className="text-white font-semibold text-xl">
                      {item.judul}
                    </h1>
                    <p className="mt-4 text-lg text-blueGray-200">
                      {item.deskripsi}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div
          className="w-full flex items-center justify-center"
          style={{ height: "17%" }}
        >
          <Swiper
            spaceBetween={0}
            slidesPerView={3}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop
            className="mb-10 h-full w-11/12 lg:w-8/12 mx-auto"
          >
            <SwiperSlide className="w-4/12 p-2">
              <div className="w-11/12 bg-white rounded-lg shadow-lg h-full flex flex-col items-center justify-center p-2">
                <p className="text-sm font-light text-center">
                  Indeks Kepuasan Masyarakat (IKM)
                </p>
                <p className="font-semibold text-lg text-center">{info.ikm}</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="w-4/12 p-2">
              <div className="w-11/12 bg-white rounded-lg shadow-lg h-full flex flex-col items-center justify-center p-2">
                <p className="text-sm font-light text-center">
                  Sistem Akuntabilitas Kinerja Instansi Pemerintah (SAKIP)
                </p>
                <p className="font-semibold text-lg text-center">
                  {info.sakip}
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="w-4/12 p-2">
              <div className="w-11/12 bg-white rounded-lg shadow-lg h-full flex flex-col items-center justify-center p-2">
                <p className="text-sm font-light text-center">
                  Indeks Reformasi Birokrasi (IRB)
                </p>
                <p className="font-semibold text-lg text-center">{info.irb}</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="w-4/12 p-2">
              <div className="w-11/12 bg-white rounded-lg shadow-lg h-full flex flex-col items-center justify-center p-2">
                <p className="text-sm font-light text-center">
                  Indeks Pelayanan Publik (IPP)
                </p>
                <p className="font-semibold text-lg text-center">{info.ipp}</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="w-4/12 p-2">
              <div className="w-11/12 bg-white rounded-lg shadow-lg h-full flex flex-col items-center justify-center p-2">
                <p className="text-sm font-light text-center">
                  Kematangan Organisasi Daerah (KOD)
                </p>
                <p className="font-semibold text-lg text-center">{info.kod}</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div> */}

      <div className="bg-blueGray-200 py-6 mt-10" id="cari">
        <h1 className="text-center text-2xl text-blueGray-800 font-semibold mb-6">
          Cari Data yang diinginkan
        </h1>
        <div className="bg-white shadow-lg rounded flex p-3 h-16 w-10/12 max-w-580-px mx-auto">
          <input
            type="text"
            className="px-2 text-lg font-light text-gray-800 bg-white flex-1 focus:outline-none rounded h-full mr-2"
            placeholder="Data Statistik..."
            value={keywords}
            onInput={(e) => setKeywords(e.target.value)}
          />
          <a
            href={`/pencarian/${keywords}`}
            className="w-16 flex justify-center items-center rounded bg-blueGray-800 text-white text-base h-full p-2"
          >
            <SearchIcon
              className="block h-6 w-6 text-white"
              aria-hidden="true"
            />{" "}
            Cari
          </a>
        </div>
      </div>
      <div
        className="flex justify-center items-center flex-col mt-10 px-8"
        id="dashboard"
      >
        <h1 className="font-semibold text-lg mb-2">Dashboard & Dataset</h1>
        <p className="text-base my-8 mb-2 text-center">
          Dashboard & Dataset menampilkan data dalam bentuk grafik dan tabel.
        </p>
        <div>
          <a
            href="/Dashboard/ListObjek"
            className="w-44 bg-blueGray-600 text-white p-2 rounded-md"
          >
            Lihat Dashboard
          </a>
        </div>
      </div>
      <main
        className="flex mt-10 px-10 w-full bg-blueGray-200 p-4  items-center flex-col md:flex-row"
        id="faq"
      >
        <div className="w-full md:w-5/12 mr-auto ml-auto mb-4 ">
          <img
            alt="..."
            src={`${ROOT_URL}/gambar_kecil.jpg`}
            className="w-full align-middle rounded-lg shadow-lg"
          />
        </div>
        <div className="flex-1 ml-2">
          <h1 className="font-semibold text-lg">Apa itu Satu Data ?</h1>
          <p className="text-base">
            Satu Data adalah inisiatif pemerintah untuk mendorong pengambilan
            kebijakan berdasarkan Data. Untuk mewujudkan hal tersebut, maka
            diperlukan pemenuhan atas data pemerintah yang akurat, terbuka, dan
            interoperabilitas. Satu Data memiliki tiga prinsip utama yaitu, satu
            standar data, satu metadata baku, dan satu portal data. Dengan
            demikian, pemanfaatan data pemerintah tidak hanya terbatas pada
            penggunaan secara internal antar instansi, tetapi juga sebagai
            bentuk pemenuhan kebutuhan data publik bagi masyarakat.
          </p>
          <h1 className="font-semibold text-lg mt-5">Latar Belakang</h1>
          <p className="text-base">
            Inisiatif Satu Data diawali dengan Portal Data Indonesia, yaitu
            data.go.id, yang diinisiasi oleh Unit Kerja Presiden Bidang
            Pengawasan Pengendalian Pembangunan (UKP-PPP) sebagai salah satu
            komitmen pemerintah dalam Open Government Partnership. <br />
            Seluruh kumpulan data yang ada di dalam data.go.id dikategorikan
            sebagai domain publik sehingga tidak diperkenankan mengandung
            informasi yang mengandung rahasia negara, rahasia pribadi atau
            hal-hal lainnya yang diatur dalam Undang-Undang Nomor 14 Tahun 2008
            tentang Keterbukaan Informasi Publik.
          </p>
          <h1 className="font-semibold text-lg mt-5">Statistik</h1>
          <p className="text-base">
            UU Republik Indonesia Nomor 16 Tahun 1997 tentang Statistik
            menegaskan Statistik adalah data yang diperoleh dengan cara
            pengumpulan, pengolahan, penyajian, dan analisis serta sebagai
            sistem yang mengatur keterkaitan antar unsur dalam penyelenggaraan
            statistik.
            <br />
            Statistik yang pemanfaatannya ditujukan untuk memenuhi kebutuhan
            instansi tertentu, dalam rangka penyelenggaraan tugas-tugas
            pemerintahan dan pembangunan yang merupakan tugas pokok instansi
            yang bersangkutan.
          </p>
        </div>
      </main>
      <div className="p-8" id="peraturan">
        <h1 className="font-semibold text-lg mb-2">Peraturan Terkait</h1>
        {dataPeraturan.map((item, key) => (
          <div>
            <a
              href={item.file.url}
              className="inline-block p-2 bg-white shadow-md rounded-md mb-2"
            >
              {item.judul}
            </a>
          </div>
        ))}
      </div>

      <div className="p-8">
        <h6 className="uppercase text-blueGray-600 text-xs font-semibold border-2 p-2 inline-block border-blueGray-600">
          Situs ini telah dikunjungi sebanyak{" "}
          <span className="text-blueGray-800">{counter ? counter : "..."}</span>{" "}
          kali
        </h6>
      </div>

      <br />
      <br />

      {/* menampilkan footer */}
      <Footer />
    </>
  );
};
