import React, { useState, useEffect } from "react";
import Axios from "axios";
import { API_URL } from "config/config";
import Navbar from "components/Navbar";
import Footer from "components/Footer.js";
import HeaderStats from "components/HeaderStats";

export default function ListContent() {
  const [dataList1, setdataList1] = useState([]);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    // handleList1();
  }, []);

  // const handleList1 = async () => {
  //   const resList1 = await Axios.get(`${API_URL}/api/list/t_tujuan`).then(
  //     (res) => res.data.t_content
  //   );
  //   setdataList1(resList1);
  // };

  const contentItems = [
    {
      content_id: 2024,
      content: "Dashboard SAKIP 2024",
      link: "https://satudata.banjarnegarakab.go.id/satudata_dashboard/",
      image: "https://img.freepik.com/free-vector/business-growth-strategy-concept-illustration_53876-40421.jpg?w=740&t=st=1692985921~exp=1692986521~hmac=f008cef5bf07a5d95419595ee28c8dc3ad3f4768c727d28658dde09e22af0e29",
    },
    {
      content_id: 2025,
      content: "Dashboard SAKIP 2025",
      link: "https://satudata.banjarnegarakab.go.id/esakip_dashboard_2025/",
      image: "https://img.freepik.com/free-vector/modern-productivity-concept-with-flat-design_23-2147970694.jpg?w=740&t=st=1692986171~exp=1692986771~hmac=0416eb5e88119bef0169590cbecd7235820bf010fc8d5544e46100e6fba3495a",
    },
    {
      content_id: 2026,
      content: "Dashboard SAKIP 2026",
      link: "https://satudata.banjarnegarakab.go.id/esakip_dashboard_2026/",
      image: "https://img.freepik.com/free-vector/smart-system-businessman-strategy-illustration-concept_1344-193.jpg?w=740&t=st=1692986307~exp=1692986907~hmac=74824764f9c2a9ecf2f7ff22c2afb9c59c11410714ed862dad517fb1421771b1",
    },
    {
      content_id: 2027,
      content: "Dashboard SAKIP 2027",
      link: "https://satudata.banjarnegarakab.go.id/esakip_dashboard_2027/",
      image: "https://img.freepik.com/free-vector/illustration-people-avatar-business-plan-concept_53876-37211.jpg?w=740&t=st=1692986518~exp=1692987118~hmac=3f6099de048b24efed43c7abfc565415052e7b947d9609a41e1051d44853caf7",
    },
  ].filter(item => item.content_id <= currentYear);

  return (
    <>
      <Navbar transparent />
      <HeaderStats />
      <main className="">
        <section className="pb-20 bg-blueGray-500">

        <div className="bg-blueGray-200 py-6">
        <h1 className="text-center text-2xl text-blueGray-800 font-semibold mb-6">
          
        </h1>
        </div>

          <div className="bg-blueGray-200 py-6">

            <h1 className="text-center text-2xl text-blueGray-800 font-semibold mb-6">
              Dashboard SAKIP
            </h1>
          </div>

          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-between gap-4">
              {contentItems.map((item) => (
                <div
                  key={item.content_id}
                  className="w-full sm:w-6/12 lg:w-3/12 px-4 mb-6"
                >
                  <a
                    href={item.link}
                    className="relative flex flex-col min-w-0 break-words shadow-lg rounded-lg bg-lightBlue-500 overflow-hidden"
                  >
                    <div className="bg-white">
                      <div
                        className="image-container"
                        style={{
                          width: "100%",
                          height: "200px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          alt={item.content}
                          src={item.image}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </div>
                    <blockquote className="relative p-4">
                      <svg
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 583 95"
                        className="absolute left-0 w-full block h-95-px -top-94-px"
                      >
                        <polygon
                          points="-30,95 583,95 583,65"
                          className="text-lightBlue-500 fill-current"
                        ></polygon>
                      </svg>
                      <h4 className="font-bold text-base text-white text-center">
                        {item.content}
                      </h4>
                    </blockquote>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
