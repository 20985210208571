import React, { useState, useEffect } from "react";
import Axios from "axios";
import { API_URL } from "config/config";
import Navbar from "components/Navbar";
import Footer from "components/Footer.js";
import HeaderStats from "components/HeaderStats";

export default function ListContent() {
  const [dataList1, setdataList1] = useState([]);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    // handleList1();
  }, []);

  // const handleList1 = async () => {
  //   const resList1 = await Axios.get(`${API_URL}/api/list/t_tujuan`).then(
  //     (res) => res.data.t_content
  //   );
  //   setdataList1(resList1);
  // };

  const contentItems = [
    {
      content_id: 2021,
      content: "Walidata & RPD",
      link: "https://satudata.banjarnegarakab.go.id/satudata_backoffice/login",
      image: "https://img.freepik.com/free-vector/social-media-data-center-smm-stats-digital-marketing-research-market-trends-analysis-female-expert-studying-online-survey-results_335657-2392.jpg?t=st=1738131988~exp=1738135588~hmac=a831e1f6c972845ac820f8d29e584ef3c5bf15cba656f03fe1c991e1d2dff1d8&w=740",
    },   
    {
      content_id: 2024,
      content: "ESAKIP 2024",
      link: "https://satudata.banjarnegarakab.go.id/satudata_backoffice/login",
      image: "https://img.freepik.com/free-vector/mobile-software-optimization-business-development_335657-166.jpg?t=st=1738131877~exp=1738135477~hmac=8e7768b51f4b04328ba039920469028923b724d427bd8d3dc35d23e8322fd25d&w=740",
    },
    {
      content_id: 2025,
      content: "ESAKIP 2025",
      link: "https://satudata.banjarnegarakab.go.id/esakip_2025/backoffice/",
      image: "https://img.freepik.com/premium-vector/2025-happy-new-year-background-design-9_1113054-23194.jpg?w=740",
    },
    {
      content_id: 2026,
      content: "Dashboard SAKIP 2026",
      link: "https://satudata.banjarnegarakab.go.id/esakip_2026/backoffice/",
      image: "https://img.freepik.com/free-vector/helping-partner-concept-illustration_114360-8867.jpg?t=st=1738129987~exp=1738133587~hmac=282bde92e3cfc2d2f3407c2a3765b7ffef1dea6a4189c41b9c228a8c42026a6c&w=740",
    },
    {
      content_id: 2027,
      content: "Dashboard SAKIP 2027",
      link: "https://satudata.banjarnegarakab.go.id/esakip_2027/backoffice/",
      image: "https://img.freepik.com/premium-vector/creative-team-launch-rocket-businesspeople-with-huge-gears-prepare-spaceship-take-off-launching-business-project_87771-25614.jpg?w=740",
    },
  ].filter(item => item.content_id <= currentYear);

  return (
    <>
      <Navbar transparent />
      <HeaderStats />
      <main className="">
        <section className="pb-20 bg-blueGray-500">

        <div className="bg-blueGray-200 py-6">
        <h1 className="text-center text-2xl text-blueGray-800 font-semibold mb-6">
          
        </h1>
        </div>

          <div className="bg-blueGray-200 py-6">
            <h1 className="text-center text-2xl text-blueGray-800 font-semibold mb-6">
              LOGIN 
            </h1>
          </div>
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-between gap-4">
              {contentItems.map((item) => (
                <div
                  key={item.content_id}
                  className="w-full sm:w-6/12 lg:w-3/12 px-4 mb-6"
                >
                  <a
                    href={item.link}
                    className="relative flex flex-col min-w-0 break-words shadow-lg rounded-lg bg-lightBlue-500 overflow-hidden"
                  >
                    <div className="bg-white">
                      <div
                        className="image-container"
                        style={{
                          width: "100%",
                          height: "200px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          alt={item.content}
                          src={item.image}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </div>
                    <blockquote className="relative p-4">
                      <svg
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 583 95"
                        className="absolute left-0 w-full block h-95-px -top-94-px"
                      >
                        <polygon
                          points="-30,95 583,95 583,65"
                          className="text-lightBlue-500 fill-current"
                        ></polygon>
                      </svg>
                      <h4 className="font-bold text-base text-white text-center">
                        {item.content}
                      </h4>
                    </blockquote>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
